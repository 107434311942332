import React, { useState, useEffect } from "react";
import "./FirstCompo.scss";
import fixIcon from "../../Images/fix_icon.png";
import apple from "../../Images/apple.png";
import fghi from "../..//Images/icons.png";

// import fghi from "../Images/desktop_banner_homepage.webp";
 
const FirstCompo = () => { 
  const [mobileNumber, setMobileNumber] = useState("");
  const [isButtonColored, setIsButtonColored] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 10) {
      // Limit to 10 digits
      setMobileNumber(value);
      setIsButtonColored(value.length === 10);
    }
    // Remove 'e' from the input value
    event.target.value = event.target.value.replace(/[e]/gi, "");
  };

  return (
    <div className="Wrapper1">
        <img src={fghi} className="bannerImage" alt="Logo"  />
  
      <div className="textContainer">
        <span className="logo1">Chat&money</span>
        <span className="title1">Create · Send · Earn</span>
        <span className="title2">A cross-platform quizchat app</span>
        <span className="title3">Play with real money</span>
        <div  className="downloadButton">
          {/* <img src={googlePlay} className="downloadImageGoogle" alt="downloadImage" /> */}
          {/* <img src={apple} className="downloadImageApple" alt="downloadImage" /> */}
        </div>

        {/* <span className="title1">Start play & earn real money !</span> */}
        {/* <div className="inputContainer">
          <div className="roundedInput">
            <div className="extention">
              <text className="extenText">+91</text>
            </div>
             <input
              // Apply the CSS class here 
              type="number"
              inputMode="numeric"
              id="mobileNumber"
              name="mobileNumber"
              value={mobileNumber}
              placeholder="Enter mobile number"
              onInput={handleChange}
              maxLength={10}
              required={true}
             
            /> 
          </div>
          <div
            className={`getotpBtn ${isButtonColored ? "getotpBtngreen" : ""}`}
          >
            <span className="getotpText">Get Link</span>
          </div>
        </div> */}
      </div>
      {/* <div className="downloadBtn">
        <img src={googlePlay} alt="Logo" width={400} height={150} />
        <img src={apple} alt="Logo" width={350} height={100} />
      </div> */}
    </div>
  );
};

export default FirstCompo;
